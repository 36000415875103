@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * Footer background image styles
 */
.footer__bg {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: map-get($z-layers, 'none');
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  background-size: cover;
  background-position: center;
  background-image: url('/assets/optimized/bg-footer-small-320w-blur.jpeg');
  backdrop-filter: blur(6px);

  @include set-breakpoint('xx-small', min, 1) {
    background-image: url('/assets/optimized/bg-footer-small-375w-blur.jpeg');
  }

  @include set-breakpoint('x-small', min, 1) {
    background-image: url('/assets/optimized/bg-footer-small-480w-blur.jpeg');
  }

  @include set-breakpoint('small', min, 1) {
    background-image: url('/assets/optimized/bg-footer-large-768w-blur.jpeg');
  }

  @include set-breakpoint('x-large', min, 1) {
    background-image: url('/assets/optimized/bg-footer-large-1024w-blur.jpeg');
  }

  @include set-breakpoint('x-high-quality', min, 1) {
    background-image: url('/assets/optimized/bg-footer-large-1920w-blur.jpeg');
  }
}

/**
 * Footer mixin 
 */

 @mixin set-footer-heigth($height) {
  .page__wrapper {
    padding-bottom: $height;
  }
  .page__footer {
    height: $height;
    .footer {
      min-height: $height;
      &__bg-overlay {
        height: $height;
      }
    }
  }
}

/**
 * Pages styles related to footer height
 */
.page:not(.page--show-navigation) {
  @include set-footer-heigth($footer-mobile-not-show-navigation);
}
.page.page--show-navigation {
  @include set-footer-heigth($footer-mobile-show-navigation);
}

@include set-breakpoint('small') {
  .page:not(.page--show-navigation) {
    @include set-footer-heigth($footer-desktop-not-show-navigation);
  }
  .page.page--show-navigation {
    @include set-footer-heigth($footer-desktop-show-navigation);
  }
}



/**
 * Footer styles
 * Base styles for footer with navigation in 320 size
 */
.footer {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &__bg-overlay {
    position: absolute;
    width: 100%;
    background-color: rgba($black, 0.82);
    z-index: map-get($z-layers, 'none');
  }

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: map-get($z-layers, 'floor');
  }

  &__content {
    position: static;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: calc-size(map-get($spacing, '100'));

    & > div:first-child {
      display: flex;
      flex-direction: column;
      gap: calc-size(map-get($spacing, '150'));
    }

    .menu {
      .menu__title {
        display: none;
        margin-bottom: calc-size(map-get($spacing, '200'));
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        margin: 0;
        gap: calc-size(map-get($spacing, '100'))
          calc-size(map-get($spacing, '200'));

        .menu__item {
          font-family: get-font('primary');
          color: $white;
          white-space: nowrap;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .address {
      position: relative;
      display: flex;
      justify-content: center;
      max-width: calc-size(600px);
      min-width: calc-size(200px);

      p,
      a {
        color: $white;
      }

      a:hover {
        color: $brand-blue;
      }

      &__data {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: calc-size(map-get($spacing, '200'));

        font-family: get-font('primary');
        font-style: normal;
        text-align: center;

        p {
          width: 90%;
        }

        .data {
          &__logo {
            width: calc-size(155px);
          }
        }

        .email,
        .contact1 {
          display: none;
        }
      }

      &__bbb-logo {
        position: absolute;
        right: 0;
        bottom: 0;

        overflow: hidden;
        height: auto;
        width: calc-size(50px);

        img {
          height: inherit;
          width: calc-size(100px);
        }
      }
    }

    .copyright {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0 calc-size(map-get($spacing, '75'));

      p {
        color: $white;
        text-decoration: underline;
      }
      span {
        max-width: 100px;
      }
    }
  }
}

@include set-breakpoint('xx-small') {
  .footer {
    &__content {
      gap: calc-size(map-get($spacing, '300'));
    }
  }
}

@include set-breakpoint('x-small') {
  .footer {
    &__content {
      gap: calc-size(map-get($spacing, '400'));
      .address {
        margin: 0 10%;
        &__data {
          align-items: flex-start;
          text-align: left;

          p {
            width: 75%;
          }
        }
        &__bbb-logo {
          width: calc-size(60px);
          img {
            width: calc-size(120px);
          }
        }
      }
    }
    .copyright {
      flex-direction: row;
      justify-content: center;
      column-gap: calc-size(map-get($spacing, '300'));
    }
  }
}

@include set-breakpoint('small') {
  .footer {
    &__content {
      gap: calc-size(map-get($spacing, '700'));

      & > div:first-child {
        flex-direction: row;
        align-items: flex-start;
        gap: 0 calc-size(map-get($spacing, '700'));
      }

      .menu {

        .menu__title {
          display: block;
          color: $dark-blue;
          font-weight: 500;
          font-size: calc-size(20px);

          margin-bottom: calc-size(map-get($spacing, '100'));
        }

        ul {
          flex-direction: column;
          gap: calc-size(map-get($spacing, '100'));
        }
      }

      .address {
        order: 2;
        margin: 0;

        &__data {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0 calc-size(map-get($spacing, '300'));
          text-align: center;

          p {
            width: 100%;
            margin: 0 5%;
          }

          .data__logo {
            width: calc-size(200px);
            margin-bottom: calc-size(map-get($spacing, '200'));
          }

          .email {
            display: block;
          }

          .data__social-links {
            margin-top: calc-size(map-get($spacing, '200'));
            margin-right: 30%;
          }
        }

        &__bbb-logo {
          bottom: -#{calc-size(map-get($spacing, '900'))};
        }
      }
    }
  }
}

@include set-breakpoint('x-large') {
  .footer {
    &__content {
      gap: calc-size(map-get($spacing, '400'));

      & > div:first-child {
        gap: 5%;
        width: 100%;
        justify-content: space-between;
      }

      .menu {
        .menu__title {
          margin-bottom: calc-size(map-get($spacing, '100'));
        }

        ul {
          flex-direction: column;
          gap: calc-size(map-get($spacing, '100'));
        }
      }

      .address {
        padding-left: 5%;
        width: 100%;

        &__data {
          column-gap: calc-size(map-get($spacing, '400'));
          text-align: left;

          p {
            margin: 0;
          }

          .contact1 {
            display: block;
          }

          .data__social-links {
            margin-top: calc-size(map-get($spacing, '100'));
          }
        }

        &__bbb-logo {
          bottom: unset;
          top: 0;
        }
      }
    }
  }
}

@include set-breakpoint('x-high-quality') {
  .footer {
    &__content {
      .address {
        margin-top: calc-size(map-get($spacing, '400'));

        .data__logo {
          margin-bottom: calc-size(map-get($spacing, '500'));
        }
      }

      .copyright {
        flex-direction: column;
        align-items: flex-start;
        margin-top: -#{calc-size(map-get($spacing, '600'))};
      }
    }
  }
}

.page:not(.page--show-navigation) {
  .footer {
    &__content {
      gap: calc-size(map-get($spacing, '500'));

      & > div {
        max-width: 600px;
        margin: 0 auto;
      }
    }

    .address {
      padding-left: 0%;

      &__data {
        // text-align: left;

        p {
          margin: 0;
          width: 80%;
        }

        .data__logo {
          margin: 0 auto;
        }
      }

      &__bbb-logo {
        top: unset;
        bottom: 0;
      }
    }

    .copyright {
      flex-direction: column;
      align-items: center;
    }
  }
}

@include set-breakpoint('x-small') {
  .page:not(.page--show-navigation) {
    .footer {
      &__content {
        .address {
          &__data {
            p {
              width: 100%;
            }

            .data__logo {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@include set-breakpoint('small') {
  .page:not(.page--show-navigation) {
    .footer {
      &__content {
        .address {
          &__data {
            p {
              text-align: left;
            }

            .data__logo {
              margin: 0 auto;
              margin-bottom: calc-size(map-get($spacing, '150'));
            }
          }
        }
      }
    }
  }
}

