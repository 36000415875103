@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * General styles for small sizes 
 */
.principal-header {
  background-color: $black;

  &:not(.principal-header--show-navigation) {
    .principal-header__logo {
      width: 28%;
    }
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    max-width: 162px;
    min-width: 90px;
    width: 50%;
  }

  &__nav {
    width: 100%;
    height: 100%;
    margin-left: 20%;
  }
}

@include set-breakpoint('small', max) {
  .principal-header {
    &__container {
      padding: calc-size(map-get($spacing, '200')) 0;
    }
  }
}

/**
 * Header variables given a specific breakpoint
 */
 $header-elements: (
  'xxx-small': (
    item: 20px,
    padding: map-get($spacing, '200'),
    sidenav: (
      width: 320px,
    ),
  ),
  'xx-small': (
    item: 20px,
    padding: map-get($spacing, '200'),
    sidenav: (
      width: 320px,
    ),
  ),
  'x-small': (
    item: 22px,
    padding: map-get($spacing, '200'),
    sidenav: (
      width: 320px,
    ),
  ),
  'small': (
    item: 24px,
    padding: map-get($spacing, '200'),
    sidenav: (
      width: 320px,
    ),
  ),
  'x-large': (
    item: 24px,
    padding: map-get($spacing, '400'),
    sidenav: (
      width: 320px,
    ),
  ),
  'xxx-large': (
    item: 18px,
    padding: map-get($spacing, '400'),
    sidenav: (
      width: 320px,
    ),
  ),
  'x-high-quality': (
    item: 18px,
    padding: map-get($spacing, '400'),
    sidenav: (
      width: 320px,
    ),
  ),
) !default;

/**
* Breakpoint styles
*/
.mat-drawer-inner-container,
.sidenav {
  @each $breakpoint, $value in $breakpoints {
    @include set-breakpoint($breakpoint) {
      font-size: calc-size(map-get($header-elements, $breakpoint, 'item'));
      width: map-get($header-elements, $breakpoint, 'sidenav', 'width');
    }
  }
}

.principal-header {
  z-index: map-get($z-layers, 'top');
  position: fixed;
  top: 0;
  width: 100%;

  min-height: $header-min-height;
  max-height: $header-max-height;
  height: $header-preferred-height;

  &__container {
    height: 100%;
    box-sizing: border-box;
  }
}

.nav {
  &.nav--aside {
    padding-top: clamp(
      $header-min-height,
      $header-preferred-height,
      $header-max-height
    );
    height: 100%;
    max-height: calc(
      100vh - clamp(
          $header-min-height,
          $header-preferred-height,
          $header-max-height
        )
    );
    overflow-y: auto;
  }
}

.page__wrapper {
  padding-top: clamp(
    $header-min-height,
    $header-preferred-height,
    $header-max-height
  );
}

/**
 * Remove stiky header on page without navigation
 */
.principal-header:not(.principal-header--show-navigation) {
  position: relative;
  z-index: map-get($z-layers, 'top');
}

.page:not(.page--show-navigation) {
  .page__wrapper {
    padding-top: 0;
  }
}
