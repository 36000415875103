import { LeadStatusNumber } from '@shared/enums/lead-status';
import { FoPageRoute } from '@shared/services/navigation.service';

export const LeadStatusRedirectToPage: { [x: number]: FoPageRoute } = {
  [LeadStatusNumber.FormRedirect]: FoPageRoute.ApplyNowForm,
  [LeadStatusNumber.ConfirmationInfo]: FoPageRoute.ApplyNowForm,
  [LeadStatusNumber.CustomerVerification]: FoPageRoute.ApplyNowForm,
  [LeadStatusNumber.PartialSSNValidationFailed]: FoPageRoute.ApplyNowForm,

  [LeadStatusNumber.IncomeInfo]: FoPageRoute.CustomerInfo,
  [LeadStatusNumber.BankVerification]: FoPageRoute.CustomerInfo,

  [LeadStatusNumber.BankAccountConfirmation]: FoPageRoute.AccountConfirmation,

  [LeadStatusNumber.ConfirmPersonalDetails]: FoPageRoute.ConfirmPersonalDetails,
  [LeadStatusNumber.AgreementViewed]: FoPageRoute.AdditionalInfo,
  [LeadStatusNumber.AdditionalInfoPending]: FoPageRoute.AdditionalInfo,
  [LeadStatusNumber.PendingReferences]: FoPageRoute.AdditionalInfo,

  [LeadStatusNumber.Completed]: FoPageRoute.CompletePage,
  [LeadStatusNumber.Funded]: FoPageRoute.CompletePage,

  [LeadStatusNumber.Denied]: FoPageRoute.DeniedPage,
  [LeadStatusNumber.Voided]: FoPageRoute.DeniedPage,
  [LeadStatusNumber.Withdrawn]: FoPageRoute.DeniedPage,

  [LeadStatusNumber.ManualOfferCalculation]: FoPageRoute.ManualReviewPage,

  // [LeadStatusNumber.OfferCalculation]: FoPageRoute.OfferPage, //

  [LeadStatusNumber.OfferConfirmation]: FoPageRoute.Programs,
  [LeadStatusNumber.OfferConfirmation_renewals]: FoPageRoute.Programs,

  // default: FoPageRoute.ErrorPage,
};
